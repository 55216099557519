<template>
    <div v-if="show" class="modal-backdrop" @click.self="closeModal">
      <div class="modal-content" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
        <button class="modal-button" @click="share">Share</button>
        <button class="modal-button" @click="copy">Copy</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits } from 'vue';
  
  defineProps({
    show: Boolean,
  });

  const emit = defineEmits(['close', 'share', 'copy']);
  
  const handleTouchStart = (e) => {
    touchStartY.value = e.touches[0].clientY;
  };
  
  const handleTouchEnd = (e) => {
    const touchEndY = e.changedTouches[0].clientY;
    if (touchStartY.value - touchEndY > 50) {
      closeModal();
    }
  };
  
  const closeModal = () => {
    emit('close');
  };
  
  const share = () => {
    const telegramId = window.Telegram.WebApp.initDataUnsafe.user.id;
  
    const textToShare = `Брат, давай слоты крутить!`;
    const encodedText = encodeURIComponent(textToShare);
    const url = `https://telegram.me/share/url?url=https://t.me/not_ton_boxes_bot/kazik?startapp=${telegramId}&text=${encodedText}`;
    window.location.href = url;
  };
  
  const copy = () => {
    const telegramId = localStorage.getItem('telegram_id');
    const textToCopy = `https://t.me/not_ton_boxes_bot/kazik?startapp=${telegramId}`;
    
    const tempInput = document.createElement('textarea');
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    
    console.log('Text copied to clipboard');
    emit('copy');
  };
  
  const touchStartY = ref(0);
  </script>
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
  }
  
  .modal-content {
    background: #1a1a1a;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease-out;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .modal-button {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    background: var(--Native-button_color, #2990FF);
    box-shadow: 0px 4px 14px 0px #2990FF78;
  }
  
  .modal-button:hover {
    background: #0056b3;
  }
  </style>  