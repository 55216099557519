<template>
    <div v-for="friend in friends" :key="friend.id">
      <div class="friend">
        <img :src="friend.photo_url" class="friend-img" />
        <p class="friend-name">@{{ friend.username }}</p>
      </div>
    </div>

    <div class="invite-container" :class="{ 'container-not-empty': friends.length > 0}">

      <!--контейнер с количеством оставшихся подарков-->
      <div class="gift-remained">
        <div class="gift-remained-text" v-if="friends.length === 0">
          Invite your frens for free boxes!
        </div>
        <div v-else-if="friends.length < 5 " class="gift-remained-text">
          <p>{{ getFreeBoxesLeft() }}</p>
        </div>
      </div>

      <!--кнопка для вызова модального окна-->
      <button class="invite" @click="showModal = true">
        invite more friends!
      </button>
    
    </div>
    <InviteComponent :show="showModal" @close="showModal = false" @share="shareReferral" @copy="copyReferral"/>
</template>

<script setup>
import { onMounted, ref} from 'vue';
import InviteComponent from '../components/InviteComponent.vue';
import axios from 'axios';

const showModal = ref(false);
const initData = ref(null);
const telegramId = ref(null);
const friends = ref([]);

const getFreeBoxesLeft = () => {
  const freeBoxes = 5 - friends.value.length;
  return `You have ${freeBoxes} free box${freeBoxes === 1 ? '' : 'es'} left`;
}

const shareReferral = () => {
  console.log('Sharing referral');
  showModal.value = false;
};

const copyReferral = () => {
  console.log('Copying referral');
  showModal.value = false;
};

const getFriends = async () => {
  const response = await axios.get('/core/api/friends/',
    {
      params: {
        user_id: telegramId.value,
      },
    }
  )
  console.log(response.data);
  friends.value = response.data.friends;
};

onMounted(() => {
  initData.value = window.Telegram.WebApp.initDataUnsafe;
  telegramId.value = initData.value.user.id;
  getFriends();
  
})

</script>

<style scoped>

.gift-remained {
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
}

.invite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  padding: 40px 16px 16px 16px;
  background: #FFFFFF1A;
  border-radius: 20px;
}

.invite-container.container-not-empty {
  margin-top: auto;
}

.friend-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.friend-name {
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
}

.friend {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: #FFFFFF1A;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 12px 16px;
}

.invite {
    width: 270px;
    height: 54px;
    padding: 15px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 590;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }
  
  .invite:hover {
    background: #0056b3;
  }
</style>  
