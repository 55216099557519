<template>
  <div class="total-balance-container">
    <p class="total-balance-title">Total balance</p>
    <div class="total-balance">
      <p class="dollar-sign">$</p>
      <p class="total-balance-value">{{ totalBalance }}</p>
    </div>
  </div>
  <div v-if="winRecords.length > 0">
    <div class="coins-list">
      <div v-for="(record, index) in winRecords" :key="index" class="coin-record">
        <img :src="getCoinImage(record.crypto_name)" alt="Coin" class="coin-image" />
        <div class="coin-details">
          <div class="coin-name-container">
            <span class="coin-name">{{ record.crypto_name }}</span>
            <!-- Отображаем рейтинг монеты из cryptoConfig с помощью getCoinRate -->
            <div class="coin-stars">
              <div v-for="(i, idx) in getCoinRate(record.crypto_name)" :key="idx">
                <img class="star-icon" :src="require('@/assets/star-icon.svg')" alt="Star" />
              </div>
            </div>
          </div>
          <!-- Отображаем общую сумму для монеты -->
          <div class="coin-total-value">
            <span class="total-value">${{ record.totalValue.toFixed(8) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>No win records found.</p>
  </div>
</template>
  
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import cryptoConfig from '@/assets/cryptoConfig.json';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRFToken'] = csrfToken;

const winRecords = ref([]); // Храним записи о выигрыше пользователя
const initData = ref(null); // Данные инициализации WebApp
const telegramId = ref(null);
const totalBalance = ref(0);

// Функция для получения изображений криптовалют
const getCoinImage = (cryptoName) => {
  return require(`@/assets/coins/${cryptoName.toLowerCase()}.png`); // Предполагается, что изображения хранятся по названию монеты
};

// Получаем данные о выигрышах пользователя
const getWinRecords = async () => {
  if (telegramId.value && initData.value) {
    try {
      const response = await axios.post('/core/api/get-win/', {
        user_id: telegramId.value,
      });
      console.log(response.data.data.win_records);

      const winRecordsData = response.data.data.win_records;

      if (winRecordsData && winRecordsData.length > 0) {
        winRecords.value = winRecordsData.map(record => {
          // Получаем курс монеты из данных сервера
          const rate = parseFloat(record.rate);
          // Количество выигранных монет
          const count = parseInt(record.count);
          // Общая сумма для этой монеты
          const totalValue = rate * count;

          // Добавляем курс, количество и общую сумму в запись
          return {
            ...record,
            rate,
            count,
            totalValue,
          };
        });

        // Вычисляем общий баланс пользователя
        totalBalance.value = winRecords.value.reduce((accumulator, record) => {
          return accumulator + record.totalValue;
        }, 0).toFixed(2);
      } else {
        winRecords.value = [];
        totalBalance.value = 0;
      }
    } catch (error) {
      console.error('Error fetching win records:', error);
    }
  }
};

// получаем рейтинг монеты
const getCoinRate = (cryptoName) => {
  const crypto = cryptoConfig.find(c => c.name === cryptoName);
  return crypto.rate;
};

onMounted(() => {
  window.Telegram.WebApp.ready();
  initData.value = window.Telegram.WebApp.initDataUnsafe;
  telegramId.value = initData.value.user.id; // Получаем telegram_id из initData

  getWinRecords(); // Запрашиваем данные о выигрышах пользователя
});
</script>
  
<style scoped>

.coin-stars {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.coin-record, .coin-name-container {
  display: flex;
}

.coin-record {
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: none;
  border-radius: 20px;
  background: #FFFFFF1A;
}

.coins-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.coin-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.coin-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 16px;
}

.coin-name {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}

.coin-count {
  color: #888;
}

.coin-name-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.total-balance-container {
  border: 1px solid #FFFFFF1A;
  background: none;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  margin-bottom: 12px;
}

.total-balance-title {
  color: #FFFFFF80;
  font-size: 13px;
  font-weight: 400;
  margin: 12px 0px;
}

.dollar-sign {
  font-size: 28px;
  color: #FFFFFF80;
  font-weight: 700;
}

.total-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.total-balance-value {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 700;
}

</style>
