<template>
    <!-- 3D карточка -->
    <div class="atropos my-atropos">
        <!-- scale container (required) -->
        <div class="atropos-scale">
          <!-- rotate container (required) -->
          <div class="atropos-rotate">
            <!-- inner container (required) -->
            <div class="atropos-inner">
                <!-- put your custom content here -->
                <div class="bg"></div>
                <div class="card-name">
                    <p class="first-word">Memecoins</p>
                    <p class="second-word">Box</p>
                </div>
                <div class="image-group">
                    <!-- Отображаем первые три изображения -->
                    <div v-for="(crypto, index) in limitedImages" :key="index" class="image-wrapper">
                      <img :src="crypto.imageSrc" :alt="crypto.name" class="circle-image" />
                    </div>
                    <!-- Показать количество оставшихся -->
                    <div v-if="totalCount > 0" class="remaining-count">
                      <p>{{ totalCount }}</p>
                    </div>
                  </div>
                <img class="border-card" :src="require('@/assets/border.svg')" />
                <div class="content">
                   <img class="main-bg" data-atropos-offset="-5" :src="require('@/assets/main-bg.png')" />
                </div>
                <button class="open-button" data-atropos-offset="5" @click="handleOpenClick">Open</button>
            </div>
          </div>
        </div>
    </div>

    <div v-if="showModal && wonCrypto" class="modal-overlay">
        <div class="modal-content">
            <img v-if="wonCrypto.image" :src="require(`@/assets/${wonCrypto.image}`)" alt="Crypto Image" class="won-crypto-image" />
            <p class="congrats-text">Congratulations! You won 1 {{ wonCrypto.name }} token!</p>
            <button @click="showModal = false" class="close-button">Close</button>
        </div>
    </div>
    
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios'
import 'atropos/css'; // Импорт стилей Atropos
import Atropos from 'atropos';
import cryptoConfig from '@/assets/cryptoConfig.json';

const showModal = ref(false);
const wonCrypto = ref(null);
const initData = ref(null);
const responseData = ref(null);
const loading = ref(false);
const error = ref(null);
const totalCount = computed(() => Math.max(cryptoConfig.length, 0));
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRFToken'] = csrfToken;

const limitedImages = computed(() =>
  cryptoConfig.slice(0, 3).map(crypto => ({
    ...crypto,
    imageSrc: require(`@/assets/coins/${crypto.name}.png`), // Подставляем название изображения
  }))
);

onMounted(() => {
  window.Telegram.WebApp.ready();
  initData.value = window.Telegram.WebApp.initDataUnsafe;
  new Atropos({
    el: document.querySelector('.atropos'),
    rotateTouch: true,
    highlight: true,
    rotateXMax: 30,
    rotateYMax: 30,
    rotate: true,
    shadow: true,
    activeOffset: 50
  });
});

function getRandomCrypto() {
    const random = Math.random();
    let cumulativeChance = 0;
    for (const crypto of cryptoConfig) {
        cumulativeChance += crypto.chance;
        if (random < cumulativeChance) {
            console.log(crypto);
            return crypto;
            
        }
    }
    return cryptoConfig[cryptoConfig.length - 1];
}

function handleOpenClick() {
    console.log(initData.value.user);
    const crypto = getRandomCrypto();
    wonCrypto.value = crypto;  // Assign the selected crypto to wonCrypto
    console.log('Полученное значение', wonCrypto.value);
    showModal.value = true;    // Show the modal
    console.log(`You got: ${crypto.name}`);
    sendWinData(crypto.name, crypto.count);  // Передаём имя монеты и количество
}

function sendWinData(cryptoName, count) {
    loading.value = true;
    axios
        .post('/core/api/save-win/', {
            crypto_name: cryptoName,
            count: count,  // Передаём количество
            user_id: initData.value.user.id,
        })
        .then((response) => {
            responseData.value = response.data.message;
            console.log('Win record saved:', response.data);
            loading.value = false;
        })
        .catch((err) => {
            error.value = 'Error occurred while saving win record.';
            console.error(err);
            loading.value = false;
        });
}

</script>

<style scoped>

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    max-width: 300px;
    position: relative;
}

.won-crypto-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.congrats-text {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.close-button {
    background-color: #6C47FF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.image-group {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 12px;
    z-index: 4;
  }
  
  .image-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -18px; /* небольшое наложение */
    border: 2px solid #2c2f33; /* цвет фона вокруг */
  }
  
  .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .remaining-count {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #42454a; /* цвет фона кружка */
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-left: -18px;
  }

.card-name{
    position: absolute;
    top: 18px;
    left: 12px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 4;
}

.first-word{
    color: white;
    font-size: 20px;
    font-weight: 590;
}

.second-word{
    color: rgba(255, 255, 255, 0.486);
    font-size: 20px;
    font-weight: 590;
}

.open-button{
    background: linear-gradient(90deg, #505258, #9e9e9e, #505258);
    color: white;
    font-size: 17px;
    font-weight: 590;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 294px;
    height: 54px;
    border-radius: 28px;
    z-index: 3;
    border: none;
}

.content {
    position: absolute;
    bottom: 8px;   
    width: 318px;
    height: 369px;
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-bg {
    width: 110%;
    height: 110%;
    z-index: 2;
}

.border-card {
    position: absolute;
    bottom: 0px;
    z-index: 3;
}
.atropos-inner {
    border-radius: 28px;
    position: relative;
}

.bg {
    width: 100%;
    height: 100%;
    background: #FFFFFF29;
}

.card { 
    width: 334px;
    height: 441px;
    border-radius: 20px;
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.my-atropos {
    width: 334px;
    height: 441px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-top: 56px;
  }

</style>
